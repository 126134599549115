const config = {
	"gatsby": {
		"pathPrefix": "/",
		"siteUrl": "https://doeec.com/",
		"gaTrackingId": null
	},
	"header": {
		"logo": "https://www.dropbox.com/s/3oanppz5syukl81/logo.png?raw=1",
		"logoLink": "https://doeec.com/",
		"title": "Doeec",
		"githubUrl": "https://github.com/shou5374/",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
		"search": {
			"enabled": false,
			"indexName": "",
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.ALGOLIA_ADMIN_KEY
		}
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
    		"/codeblock"
		],
		"links": [
			{ "text": "Doeec", "link": "https://doeec.com/"},
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "Doeec",
		"description": "welcome to doeec !",
		"ogImage": null,
		"docsLocation": "https://github.com/shou5374/doeec/tree/master/content",
		"favicon": "https://www.dropbox.com/s/3oanppz5syukl81/logo.png?raw=1"
	},
};

module.exports = config;